import isMobile from 'is-mobile';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';

import { ActionMenu } from '~/components/action-menu';
import { Avatar } from '~/components/avatar';
import { Box } from '~/components/box';
import { IconButton } from '~/components/button';
import { Hotkey } from '~/components/hotkeys';
import { DesktopMenu, Masthead } from '~/components/masthead';
import MobileMenu from '~/components/masthead/MobileMenu';
import { useModalContext } from '~/components/modal';
import { Profile } from '~/components/profile';
import { useCampaignActions } from '~/features/campaigns/actions';
import { useGlobalActions } from '~/features/global/actions';
import { useMeetingActions } from '~/features/meetings/actions';
import { usePlaylistActions } from '~/features/playlists/actions';
import { WorkspaceSwitcher } from '~/features/workspaces/components/WorkspaceSwitcher';
import { useWorkspaces } from '~/features/workspaces/utils/useWorkspaces';
import { useAuth } from '~/utils/auth';
import { useFlags } from '~/utils/feature-flags';
import { usePermissions } from '~/utils/permissions';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const GlobalSearch = lazy(() => import('~/features/search/components/GlobalSearch'));

const WrapMain = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  alignItems: 'center',
  gap: '$small',
  overflow: 'hidden',
  flex: 1
});

const WrapActions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$tiny',
  marginLeft: '$small'
});

const WrapWorkspaceSwitcher = styled('div', {
  display: 'flex',
  overflow: 'hidden',
  maxWidth: '20rem'
});

const Divider = styled('div', {
  height: '$small',
  width: '1px',
  background: '$dark-80'
});

const AddButton = styled(IconButton, {
  margin: '0 $tiny'
});

function DashboardMasthead() {
  const auth = useAuth();
  const router = useRouter();
  const workspaces = useWorkspaces();
  const permissions = usePermissions();
  const flags = useFlags(['homepage-uplift', 'vouch-meetings']);

  const globalActions = useGlobalActions();
  const campaignActions = useCampaignActions();
  const playlistActions = usePlaylistActions();
  const meetingActions = useMeetingActions();

  const tagsEnabled = permissions.canUseFeature('tags:update');
  const templatesEnabled = true; // TODO: add permission on the BE

  const modalContext = useModalContext();
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);

  // Keyboard shortcuts for global search (if enabled) and the support button
  // in the masthead
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.target === window.document.body) {
        switch (e.key) {
          case '/':
            if (modalContext.modals?.length === 0) {
              e.preventDefault();
              setShowGlobalSearch(true);
            }
            break;

          case '?':
            e.preventDefault();
            globalActions?.contactUs({ type: 'dashboard-masthead' });
            break;
        }
      }
    };

    window.document.body.addEventListener('keydown', handleKeydown);
    return () => window.document.body.removeEventListener('keydown', handleKeydown);
  }, [globalActions, modalContext.modals]);

  const showUserGuide = flags?.['homepage-uplift']?.enabled;
  const showMeetings = flags?.['vouch-meetings']?.enabled;

  const showApiSettings =
    permissions.canUseFeature('developer:embed') ||
    permissions.canUseFeature('developer:rest') ||
    permissions.canUseFeature('developer:restmedia');

  const showWebhookSettings = permissions.canUseFeature('developer:webhook');
  const showDevelopersSettings = showApiSettings || showWebhookSettings;

  const [active, setActive] = useState(false);

  const mainMenuItems = useMemo(
    () => [
      { label: 'Home', href: '/dashboard/home' },
      { label: 'Requests', href: '/dashboard/requests' },
      { label: 'Videos', href: '/dashboard/library' },
      { label: 'Playlists', href: '/dashboard/playlists' },
      { label: 'Analytics', href: '/dashboard/analytics' }
    ],
    []
  );

  const accountName = auth?.account?.name ?? 'Unknown';
  const accountEmail = auth?.account?.email;
  const avatarSrc = auth?.account?.avatarSrc;

  const profileMenuItems = useMemo(
    () =>
      [
        { label: 'Contacts', href: '/dashboard/contacts' },
        { label: 'Tags', href: '/dashboard/tags', visible: tagsEnabled },
        { label: 'Templates', href: '/dashboard/templates', visible: templatesEnabled },
        { label: 'Integrations', href: '/dashboard/apps' },
        { label: 'Downloads', href: '/dashboard/downloads' },

        { isSeparator: true, label: 'Settings' },
        { label: 'Account settings', href: '/dashboard/settings/?setting=profile' },
        { label: 'Organization settings', href: '/dashboard/settings/?setting=entity' },
        { label: 'Developer settings', href: '/dashboard/settings/?setting=api', visible: showDevelopersSettings },

        { isSeparator: true, label: 'Help & support' },
        { label: 'User guide', href: '/dashboard/user-guide', visible: showUserGuide },
        { label: 'Contact us', onClick: () => globalActions.contactUs({ type: 'masthead' }) },

        { isSeparator: true },
        {
          label: 'Logout',
          href: '/sign-out'
        }
      ].map((item) =>
        'href' in item && item.href
          ? {
              ...item,
              selected: router.pathname.startsWith(item.href.toString())
            }
          : item
      ),
    [tagsEnabled, templatesEnabled, showUserGuide, showDevelopersSettings, router.pathname, globalActions]
  );

  const globalAddItems = useMemo<ComponentProps<typeof ActionMenu>['items']>(
    () => [
      {
        label: 'Create request',
        onClick: () => campaignActions.createCampaign()
      },
      {
        label: 'Create video recording',
        onClick: () => globalActions.createRecording({ type: 'RECORDING', recordingType: 'VIDEO' })
      },
      {
        label: 'Create screen recording',
        onClick: () => globalActions.createRecording({ type: 'RECORDING', recordingType: 'SCREEN' }),
        visible: !isMobile({ tablet: true })
      },
      {
        label: 'Upload video',
        onClick: () => globalActions.uploadVideo()
      },
      {
        label: 'Create playlist',
        onClick: () => playlistActions.createPlaylist()
      },
      {
        label: 'Create meeting',
        onClick: () => meetingActions.createMeeting(),
        visible: showMeetings
      }
    ],
    [showMeetings, campaignActions, globalActions, playlistActions, meetingActions]
  );

  return (
    <>
      <Suspense fallback={null}>
        <GlobalSearch visible={showGlobalSearch} setVisible={setShowGlobalSearch} />
      </Suspense>

      <Masthead>
        <WrapMain>
          <IconButton
            color="grey"
            icon="menu"
            onClick={() => setActive(true)}
            aria-label="Open menu"
            css={{
              '@bp-medium': {
                display: 'none'
              }
            }}
          />
          <WrapWorkspaceSwitcher>
            <WorkspaceSwitcher interactive />
          </WrapWorkspaceSwitcher>
          <Box
            css={{
              width: 'auto',
              display: 'none',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '$small',

              '@bp-medium': {
                display: 'flex'
              }
            }}
          >
            <Divider />
            <DesktopMenu items={mainMenuItems} />
          </Box>
        </WrapMain>

        <WrapActions>
          <ActionMenu items={globalAddItems}>
            {(props) => <AddButton color="brand" icon="add" aria-label="Create new" {...props} />}
          </ActionMenu>
          <IconButton
            color="grey"
            icon="search"
            tooltip={<Hotkey hotkey="/">Search</Hotkey>}
            onClick={() => setShowGlobalSearch(true)}
          />
          <ActionMenu
            items={[
              {
                isCustom: true,
                Content: () => (
                  <Box css={{ width: '14rem', padding: '$small', overflow: 'hidden' }}>
                    <Profile avatarSrc={avatarSrc} name={accountName} email={accountEmail} />
                  </Box>
                )
              },
              ...profileMenuItems
            ]}
            placement="bottom-end"
            menuHeight="full"
          >
            {(props) => (
              <Avatar
                imageSrc={avatarSrc}
                size="medium"
                name={accountName}
                round
                css={{ cursor: 'pointer' }}
                {...props}
              />
            )}
          </ActionMenu>
        </WrapActions>
      </Masthead>

      <MobileMenu
        workspaces={workspaces}
        items={[...mainMenuItems, { isSeparator: true }, ...profileMenuItems]}
        active={active}
        setActive={setActive}
        name={accountName}
        email={accountEmail}
        avatarSrc={avatarSrc}
        showUpgradeButton={auth?.entity?.showUpgrade}
        css={{ '@bp-medium': { display: 'none' } }}
      />
    </>
  );
}

export { DashboardMasthead };
